import { CSSProperties, useState } from "react"
import styled, { css } from "styled-components"
import { FormProps } from "../interfaces/ContactInterfaces"
import { device } from "../styles/stylesConfig"
import { firestore } from "../firebase/firebase-config"
import { addDoc, collection } from "firebase/firestore"
import { showMixinToast } from "../utils/Alerts"
import { useLogEvent } from "../hooks/useLogEvent"

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background: #fff;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    position: relative;
`

const ContentContainer = styled.div`
    width: 80%;
    max-width: 650px;
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 50px;
`

const TitleDiv = styled.div`
    width: 100%;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & > h1 {
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin: 0 0 30px;
    }

    & > h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        & > span {
        font-weight: 600;
    }
    }
`

const SocialDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 30px 0px 40px;

    @media ${device.md} {
        flex-direction: column;
    }
`

const SocialButton = styled.a`
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 50px;
    transition: all 0.3s ease-in-out;

    &:first-of-type {
        @media ${device.md} {
            margin-bottom: 20px;
            margin-right: 0px;
        }

        & > img {
            width: 19px;
        }
    }

    &:last-of-type {
        margin-right: 0px;

        & > img {
            width: 20px;
        }
    }

    & > img {
        width: 20px;
        height: auto;
        margin-right: 10px;
    }

    & > h3 {
        font-size: 18px;
        color: #333;
        font-weight: 400;
        transition: all 0.2s ease-in-out;

        @media ${device.xs} {
            font-size: 14px;
        }

    }

    &:hover {
        cursor: pointer;
        
        & > h3 {
            color: #4ac6b7;
        }
    }

`

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin: 10px 0px;

        & > hr {
            width: 30px;
            border: none;
        }

        @media ${device.md} {
            flex-direction: column;
            & > hr {
                height: 20px;
            }
        }
        
    }
`

const Input = styled.input<{ incomplete: string }>`
    width: 100%;
    background: #FFF;
    background-size: cover;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 12px;
    border: 1px solid #333;
    font-size: 18px;
    transition: all 0.5s ease-in-out;

    @media ${device.md} {
        font-size: 14px;
    }

    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        background-color: #fff0f0;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    &:focus {
        outline: 0 none;
        border-color: #4ac6b7;
        background: #e8f8f6;
        box-shadow: 0 0 0 1px #4ac6b7 inset;
    }
`

const TextArea = styled.textarea<{ incomplete: string }>`
    width: 100%;
    min-height: 100px;
    max-width: 624px;
    background: #FFF;
    background-size: cover;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 12px;
    border: 1px solid #333;
    font-size: 18px;

    @media ${device.md} {
        font-size: 14px;
    }

    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        background-color: #fff0f0;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    &:focus {
        outline: 0 none;
        border-color: #4ac6b7;
        background: #e8f8f6;
        box-shadow: 0 0 0 1px #4ac6b7 inset;
    }
`

const InputDescription = styled.legend`
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    opacity: 0.7;
    color: #333;
    text-align: left;
    margin: 10px 0px;
`

const SendButton = styled.button`
    position: relative;
    background: #4ac6b7;
    border-radius: 2px;
    font-size: 22px;
    font-weight: 600;
    color: white;
    padding: 10px 30px;
    cursor: pointer;
    display: inline-block;
    transition: all 0.3s ease;
    align-self: end;
    margin: 20px 0px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(255, 255, 255, 0.1);
    }

    &:hover {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);

        &::before {
            animation: fillButton 0.5s ease-in-out forwards;
        }
    }

    @media ${device.md} {
        width: 100%;
    }
`

export const ContactForm = () => {

    const { handleLogEvent } = useLogEvent();

    const [form, setForm] = useState<FormProps>({
        name: '',
        email: '',
        phone: '',
        message: '',
    })


    const [fieldIncomplete, setFieldIncomplete] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
    })

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            name: form.name.trim() === '',
            email: form.email.trim() === '' || !form.email.trim().includes('@'),
            phone: form.name.trim() === '',
            message: form.message.trim() === '',
        });

        if (form.name.trim() === '') {
            existError = true;
        }
        if (form.email.trim() === '') {
            existError = true;
        }
        if (form.phone.trim() === '') {
            existError = true;
        }
        if (form.message.trim() === '') {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async () => {

        if (!formValidations()) {

            setForm({
                name: '',
                email: '',
                phone: '',
                message: '',
            })

            setFieldIncomplete({
                name: false,
                email: false,
                phone: false,
                message: false,
            })

            await addDoc(collection(firestore, "Emails"), form);

            handleLogEvent('SendEmail');

            showMixinToast('Mensaje enviado con exito!', 'success');

        }

    }

    const styleCompleted: CSSProperties = {
        outline: '0 none',
        borderColor: '#4ac6b7',
        background: '#e8f8f6',
        boxShadow: '0 0 0 1px #4ac6b7 inset'
    }

    return (


        <Container id="home">

            <ContentContainer>

                <TitleDiv className="utils__fade-in-fast">

                    <h1>Contacto</h1>

                    <h2><span>Hablemos,</span> tal vez hacemos un buen equipo.</h2>

                </TitleDiv>

                <SocialDiv className="utils__fade-in">

                    <SocialButton
                        target="_blank"
                        href="https://wa.me/+5491124078902?text=Hola Batata! Quiero pedirles una página web."
                        rel="noreferrer"
                        onClick={() => { handleLogEvent('ButtonWsContact'); }}
                    >

                        <img
                            src={`${process.env.REACT_APP_CDN}/whatsapp-aqua.png`}
                            alt="WhatsApp"
                        />

                        <h3>+54 911 2407 - 8902</h3>

                    </SocialButton>

                    <SocialButton
                        target="_blank"
                        href="mailto:batata.agenciadigital@gmail.com"
                        rel="noreferrer"
                        onClick={() => { handleLogEvent('ButtonMailContact'); }}
                    >

                        <img
                            src={`${process.env.REACT_APP_CDN}/correo-aqua.png`}
                            alt="correo"
                        />

                        <h3>batata.agenciadigital@gmail.com</h3>

                    </SocialButton>

                </SocialDiv>

                <FormContainer className="utils__fade-in">

                    <div>

                        <Input
                            type={"text"}
                            placeholder={'Nombre'}
                            value={form.name}
                            incomplete={fieldIncomplete.name.toString()}
                            onChange={(e: any) => {

                                setFieldIncomplete({ ...fieldIncomplete, name: false })
                                setForm({
                                    ...form,
                                    name: e.target.value
                                })
                            }}
                            style={form.name.trim() !== '' ? { ...styleCompleted } : {}}
                        />

                    </div>

                    <div>

                        <Input
                            type={"text"}
                            placeholder={'Email'}
                            value={form.email}
                            incomplete={fieldIncomplete.email.toString()}
                            onChange={(e: any) => {
                                setFieldIncomplete({ ...fieldIncomplete, email: false })
                                setForm({
                                    ...form,
                                    email: e.target.value
                                })
                            }}
                            style={form.email.trim() !== '' ? { ...styleCompleted } : {}}
                        />

                        <hr />

                        <Input
                            type={"text"}
                            placeholder={'Teléfono'}
                            value={form.phone}
                            incomplete={fieldIncomplete.phone.toString()}
                            onChange={(e: any) => {
                                setFieldIncomplete({ ...fieldIncomplete, phone: false })
                                setForm({
                                    ...form,
                                    phone: e.target.value
                                })
                            }}
                            style={form.phone.trim() !== '' ? { ...styleCompleted } : {}}
                        />

                    </div>

                    <InputDescription>
                        Dejanos tu mensaje!
                    </InputDescription>

                    <div>

                        <TextArea
                            placeholder={'Mensaje'}
                            value={form.message}
                            incomplete={fieldIncomplete.message.toString()}
                            onChange={(e: any) => {
                                setFieldIncomplete({ ...fieldIncomplete, message: false })
                                setForm({
                                    ...form,
                                    message: e.target.value
                                })
                            }}
                            style={form.message.trim() !== '' ? { ...styleCompleted } : {}}
                        />

                    </div>

                </FormContainer>

                <SendButton
                    onClick={handleFormConfirm}
                    className="utils__fade-in"
                >
                    Enviar
                </SendButton>


            </ContentContainer>


        </Container>

    )
}
