import { useEffect } from "react";
import { Header } from "../components/Header"
import { scrollFadeIn, scrollFadeInFast } from "../effects/Observers"
import { ContactForm } from "../components/ContactForm";

export const ContactScreen = () => {

    useEffect(() => {
        scrollFadeIn()
        scrollFadeInFast()
    }, [])

    return (

        <div>

            <Header headerStyles={{ backgroundColor: `rgba(179,179,179,0.9)`, borderColor: `#b3b3b3` }} />

            <ContactForm />


        </div>


    )
}
