import styled from "styled-components";
import { device } from "../styles/stylesConfig";

const Hamburger = styled.label`
    width: 45px;
    height: 45px;
    border: 1px solid white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 50px;
    visibility: hidden;
    z-index: 2;

    @media ${device.md} {
        visibility: visible;
        width: 40px;
        height: 40px;
    }

    @media ${device.sm} {
        right: 20px;
    }

    & > input {
        display: none;
    }

    & > svg {
        position: absolute;
        bottom: 0;
        top: 2px;
        left: 0;
        right: 2px;
        margin: auto;
        /* The size of the SVG defines the overall size */
        height: 25px;
        /* Define the transition for transforming the SVG */
        transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);

        @media ${device.md} {
            height: 25px;
        }
    }

    & > input:checked + svg {
        transform: rotate(-45deg);
    }

    & > input:checked + svg > path:first-of-type {
        stroke-dasharray: 20 300;
        stroke-dashoffset: -32.42;
    }

`

const Line = styled.path`
    fill: none;
    stroke: white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

    &:first-of-type {
        stroke-dasharray: 12 63;
    }
`
interface Props {
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
}

export const MenuButton = ({ showMenu, setShowMenu }: Props) => {

    return (

        <Hamburger>
            <input type="checkbox" onChange={() => setShowMenu(!showMenu)} />
            <svg viewBox="0 0 32 32">
                <Line d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></Line>
                <Line d="M7 16 27 16"></Line>
            </svg>
        </Hamburger>

    )
}
