document.addEventListener("DOMContentLoaded", function () {
    scrollFadeIn();
    scrollFadeInFast();
    servicesScroll();
    worksScroll();
});

export function scrollFadeIn() {

    const faders = document.querySelectorAll('.utils__fade-in');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFast() {

    const faders = document.querySelectorAll('.utils__fade-in-fast');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

function servicesScroll() {

    const list = document.querySelector('.services__list');

    if (list !== null) {
  
    list.addEventListener('mouseenter', function() {
      list.style.animationPlayState = 'paused';
    });
  
    list.addEventListener('mouseleave', function() {
      list.style.animationPlayState = 'running';
    });

}

}


function worksScroll() {

    const list = document.querySelector('.works__list');

    if (list !== null) {
  
    list.addEventListener('mouseenter', function() {
      list.style.animationPlayState = 'paused';
    });
  
    list.addEventListener('mouseleave', function() {
      list.style.animationPlayState = 'running';
    });

    }
}
