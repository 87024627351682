import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useHistory, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { useLogEvent } from "../hooks/useLogEvent"


const Container = styled.div`
width: 100%;
background: #FFF;
background-size: cover;
z-index: 1;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
padding: 5vh 0vw;


`
const Title = styled.h2`
width: 80%;
color: #965F8A;
font-size: 28px;
font-weight: 600;
margin-bottom: 40px;
text-transform: capitalize;

`

const ContentContainer = styled.div`
width: 80%;
display: grid;
grid-template-columns: repeat(3, 1fr);
column-gap: 50px;

@media ${device.lg} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;

}

@media ${device.md} {
    grid-template-columns: repeat(1, 1fr);
}

`

const DataDiv = styled.div`
    width: 100%;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @media ${device.lg} {
        width: auto;

        &:nth-of-type(1) {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        &:nth-of-type(2) {
            grid-column-start: 2;
            grid-column-end: 3;
        }

        &:nth-of-type(3) {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    @media ${device.md} {
        width: auto;

        &:nth-of-type(1) {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        &:nth-of-type(2) {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        &:nth-of-type(3) {
            grid-column-start: 2;
            grid-column-end: 3;
        }
    }
    @media ${device.sm} {
        width: auto;

        &:nth-of-type(1) {
            grid-column-start: unset;
            grid-column-end: unset;
        }

        &:nth-of-type(2) {
            grid-column-start: unset;
            grid-column-end: unset;
        }

        &:nth-of-type(3) {
            grid-column-start: unset;
            grid-column-end: unset;
        }
    }

    & > div:nth-of-type(1) {
        border-left: 3px solid #4ac6b7;
    }

    & > div:nth-of-type(2) {
        border-left: 3px solid #965F8A;
    }

    & > div:nth-of-type(3) {
        border-left: 3px solid #ffc36f;
    }
`


const ContactUsInfo = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    position: relative;
    overflow: hidden;

    & > img {
        width: 20px;
        height: auto;
        margin-left: 30px;
        margin-right: 10px;
    }

    & > button,
    & > a {
        color: #333;
        font-size: 16px;
        font-weight: 400;

        &:hover {
            cursor: pointer;
            color: #965F8A;
            font-weight: 500;
            text-decoration: underline;
        }
    }

    @media ${device.sm} {
        & > img {
            width: 16px;
            margin-left: 10px;
            margin-right: 5px;
        }

        & > button,
        & > a {
            font-size: 12px;
        }
    }

`

const ContactUsInfoLocation = styled(ContactUsInfo)`

    & > button {

        &:hover {
            cursor: default;
            color: #333;
            font-weight: 400;
            text-decoration: none;
        }
    }

`

const Reviews = styled.a`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none !important;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    padding: 20px;

    & > img {
        width: 130px;
        height: auto;
        margin-bottom: 50px;

        @media ${device.sm} {
            width: 100px;
            margin-bottom: 30px;
        }
    }

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & > img {
            width: 30px;
            height: auto;
            margin-right: 5px;

            &:last-of-type {
                margin-right: 0px;
            }

            @media ${device.sm} {
                width: 20px;
            }
        }
    }

    &:hover {
        background-color: rgb(255, 195, 111, 0.3);
    }

`

const Social = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none !important;

    & > h3 {
        font-size: 22px;
        color: #ccc;
        margin-bottom: 30px;

        @media ${device.sm} {
            font-size: 20px;
        }
    }

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & > a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            margin-right: 20px;
            transition: all .3s ease-in-out;

            @media ${device.sm} {
                width: 40px;
                height: 40px;
            }

            &:hover {
                background-color: rgba(150, 95, 138, 0.5);
                cursor: pointer;

                & > img {
                    transform: scale(0.8);
                }

            }

            &:last-of-type {
                margin-right: 0px;

                &:hover {
                    background-color: rgba(74, 198, 183, 0.5);
                }
                & > img {
                    margin-left: 2px;
                }
            }

            & > img {
                width: 30px;
                height: auto;
                transition: all .3s ease-in-out;

                @media ${device.sm} {
                    width: 25px;
                }
            }
        }
    }

`


export const Footer = () => {

    const history = useHistory();

    const { handleLogEvent } = useLogEvent();

    // const [languajeSelected, setLanguajeSelected] = useState<'es' | 'en'>(localStorage.getItem('languaje') as 'es' | 'en' ?? 'es');
    const [languajeSelected, setLanguajeSelected] = useState<'es' | 'en'>('es');

    // useEffect(() => {

    //     if (location.pathname === '/es') {
    //         localStorage.setItem('languaje', 'es');
    //         setLanguajeSelected('es');
    //     } else {
    //         localStorage.setItem('languaje', 'en');
    //         setLanguajeSelected('en');
    //     }

    // }, [location])

    return (

        <Container>

            <Title className='utils__fade-in-fast'>Contactanos!</Title>

            <ContentContainer>

                <DataDiv>

                    <ContactUsInfo className='utils__fade-in'>

                        <img
                            src={`${process.env.REACT_APP_CDN}/whatsapp.png`}
                            alt="WhatsApp"
                        />

                        <a
                            target="_blank"
                            href="https://wa.me/+5491124078902?text=Hola Batata! Quiero pedirles una página web."
                            rel="noreferrer"
                            onClick={() => { handleLogEvent('ButtonWsFooter') }}
                        >+54 911 2407 - 8902
                        </a>

                    </ContactUsInfo>

                    <ContactUsInfo className='utils__fade-in'>

                        <img
                            src={`${process.env.REACT_APP_CDN}/correo.png`}
                            alt="correo"
                        />

                        <button
                            onClick={() => {
                                history.push(`/${languajeSelected}/contact`)

                                handleLogEvent('ButtonMailFooter')
                            }}
                        >
                            batata.agenciadigital@gmail.com
                        </button>


                    </ContactUsInfo>

                    <ContactUsInfoLocation className='utils__fade-in'>

                        <img
                            src={`${process.env.REACT_APP_CDN}/ubicacion.png`}
                            alt="Ubicacion"
                        />

                        <button>Buenos Aires, Argentina</button>


                    </ContactUsInfoLocation>

                </DataDiv>

                <DataDiv>

                    <Reviews
                        target="_blank"
                        href="https://g.page/r/CSqo5R_VDCO0EB0/review"
                        rel="noreferrer"
                        className='utils__fade-in'
                        onClick={() => { handleLogEvent('ButtonReviews') }}
                    >

                        <img
                            src={`${process.env.REACT_APP_CDN}/google.png`}
                            alt="google"
                        />

                        <div>
                            <img
                                src={`${process.env.REACT_APP_CDN}/estrella.png`}
                                alt="estrella"
                            />
                            <img
                                src={`${process.env.REACT_APP_CDN}/estrella.png`}
                                alt="estrella"
                            />
                            <img
                                src={`${process.env.REACT_APP_CDN}/estrella.png`}
                                alt="estrella"
                            />
                            <img
                                src={`${process.env.REACT_APP_CDN}/estrella.png`}
                                alt="estrella"
                            />
                            <img
                                src={`${process.env.REACT_APP_CDN}/estrella.png`}
                                alt="estrella"
                            />
                        </div>

                    </Reviews>

                </DataDiv>

                <DataDiv>

                    <Social className='utils__fade-in'>

                        <h3>Seguinos.</h3>

                        <div>

                            <a
                                target="_blank"
                                href="https://www.instagram.com/batata.agencia"
                                rel="noreferrer"
                                onClick={() => { handleLogEvent('ButtonIgFooter') }}
                            >

                                <img
                                    src={`${process.env.REACT_APP_CDN}/instagram.png`}
                                    alt="instagram"
                                />

                            </a>

                            <a
                                target="_blank"
                                href="https://wa.me/+5491124078902?text=Hola Batata! Quiero pedirles una página web."
                                rel="noreferrer"
                                onClick={() => { handleLogEvent('ButtonWsFooter') }}
                            >

                                <img
                                    src={`${process.env.REACT_APP_CDN}/whatsapp-social.png`}
                                    alt="whatsapp"
                                />

                            </a>

                        </div>

                    </Social>

                </DataDiv>

            </ContentContainer>

        </Container>

    )
}
