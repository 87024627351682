import styled, { css } from "styled-components";
import { device } from "../styles/stylesConfig";
import { useState } from "react";
import { useAnimations } from "../effects/Effects";
import { useHistory } from "react-router-dom";
import { useLogEvent } from "../hooks/useLogEvent";

const MenuContainer = styled.div<{ show: string }>`
    width: 100vw;
    display: flex;
    color: white;
    background: rgba(255,195,111,0.9);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 1;
    transition: background-color 0.5s ease-in-out;
    visibility: hidden;

    @keyframes rainbow {
        0% {
        background: rgba(255,195,111,0.9);
        }
        10% {
            background: rgba(255,195,111,0.9);
        }
        17% {
            background: rgba(146,221,211,0.9);
        }
        27% {
            background: rgba(146,221,211,0.9);
        }
        34% {
            background: rgba(150,95,138,0.9);
        }
        43% {
            background: rgba(150,95,138,0.9);
        }
        50% {
            background: rgba(255,112,113,0.9);
        }
        60% {
            background: rgba(255,112,113,0.9);
        }
        67% {
            background: rgba(150,95,138,0.9);
        }
        74% {
            background: rgba(150,95,138,0.9);
        }
        84% {
            background: rgba(146,221,211,0.9);
        }
        93% {
            background: rgba(146,221,211,0.9);
        }
        100% {
            background: rgba(255,195,111,0.9);
        }
    }

    @keyframes openModal {
        0% {
            height: 0vh;
        }
        100% {
            height: 100vh;
        }
    }

    @keyframes closeModal {
        0% {
            height: 100vh;
        }
        100% {
            height: 0vh;
        }
    }

    @keyframes closeText {
        0% {
            font-size: 28px;
            @media ${device.xs} {
                font-size: 20px;
            }
        }
        100% {
            font-size: 0px;
        }
    }


    @media ${device.md} {
        visibility: visible;
    }

    ${({ show }) => show === 'false' && css`

        & > div {
            font-size: 0px;
            animation: closeText 0.2s normal;
            visibility: hidden;
        }

        height: 0vh;
        animation: closeModal 0.3s normal;


    `}

    ${({ show }) => show === 'true' && css`

        height: 100vh;
        animation: rainbow 30s infinite, openModal 0.3s normal;
        

    `}
`

const NavButton = styled.div`
    color: white;
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 5px;
    padding: 15px 0px;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
    &:hover {
        border-color: white;
        background-color: rgba(255,255,255,0.1);
    }

    @media ${device.lg} {
        /* font-size: 14px;
        width: 130px; */
    }

    @media ${device.sm} {
        /* font-size: 10px; */
    }

    @media ${device.xs} {
        font-size: 20px;
    }

`

interface Props {
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Menu = ({ showMenu, setShowMenu }: Props) => {

    const {
        handleClickGoHome,
        handleClickGoPortfolio,
        handleClickGoWeb
    } = useAnimations()

    const history = useHistory();

    const { handleLogEvent } = useLogEvent();

    const [languajeSelected, setLanguajeSelected] = useState<'es' | 'en'>('es');

    return (

        <MenuContainer show={showMenu.toString()} >

            <NavButton onClick={async () => { setShowMenu(false); await history.push(`/${languajeSelected}`); await handleClickGoPortfolio(); handleLogEvent('goPortfolioMenu'); }}>Portfolio</NavButton>

            <NavButton onClick={async () => { setShowMenu(false); await history.push(`/${languajeSelected}/web`); await handleClickGoWeb(); handleLogEvent('goWebMenu'); }}>Desarrollo Web</NavButton>

            <NavButton onClick={async () => { setShowMenu(false); await history.push(`/${languajeSelected}/contact`); await handleClickGoHome(); handleLogEvent('goContactMenu'); }}>Contacto</NavButton>

        </MenuContainer>

    )
}
