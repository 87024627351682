import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { WebSteps } from "./WebSteps"

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background: #58c480;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

const ContentContainer = styled.div`
    width: 80%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

const ImageWeb = styled.div<{ img: string }>`
    width: 240px;
    height: 240px;
    margin-top: 150px;
    animation:  levitate 3s ease-in-out infinite;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
        
    `}

`

const TitleDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 50px 0 30px;

    @media ${device.md} {
        margin: 50px 0 0;
    }

    h1 {
        color: white;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 30px;

    }

    p {
        color: white;
        font-size: 18px;
        line-height: 1.6;
        margin: 0 auto 25px;
        max-width: 670px;
        text-align: center;
    }

    span {
        font-weight: 600;
        text-decoration: underline;
    }
`

const InfoContainer = styled.div`
    width: 100%;
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;

    @media ${device.md} {
        flex-direction: column;
        align-items: center;
    }
`

const Info = styled.div`
    width: 45%;
    
    @media ${device.md} {
        width: 90%;

        &:last-of-type {
            margin-top: 50px;
        }
    }

    h2 {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: bold;
        color: white;
        text-align: center;
    }

    p {
        font-size: 18px;
        line-height: 1.5;
        color: white;
        text-align: justify;
    }

`



export const WebIntro = () => {

    return (

        <Container id="web">

            <ContentContainer >

                <ImageWeb img={`${process.env.REACT_APP_CDN}/icon-web.png`} />

                <TitleDiv className='utils__fade-in-fast'>

                    <h1>Desarrollo Web</h1>

                    <p><span>Artesanos del código fuente.</span> Desde el diseño hasta la última línea de código buscamos la perfección. A veces casi la conseguimos.</p>

                    <p>Te acompañamos en todo el proceso desde la idea hasta la puesta online!</p>

                </TitleDiv>

                <InfoContainer className='utils__fade-in-fast'>

                    <Info>

                        <h2>Construimos confianza</h2>

                        <p>Nuestro motor es acompañar el crecimiento de nuestros clientes con trabajos de los que ambos podamos estar orgullosos.</p>

                    </Info>

                    <Info>

                        <h2>Siempre con vos</h2>

                        <p>Te damos un mantenimiento constante para que puedas tener tu página web siempre al día.</p>

                    </Info>

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
