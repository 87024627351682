import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useHistory, useLocation } from "react-router-dom"
import { useAnimations } from "../effects/Effects"
import { useState } from "react"
import { useLogEvent } from "../hooks/useLogEvent"

const Container = styled.div`
    width: 100%;
    background-color: #58c480;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: 2;
        animation: blink 0.5s ease-in;
    }
`

const ContentContainer = styled.div<{ position: 'left' | 'right' }>`
    width: 100%;
    min-height: 500px;
    background: #92ddd3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;

    ${({ position }) => position === 'right' ? css`
        align-items: end;
    `: css`
        align-items: start;
    `}

    @media ${device.sm} {
        align-items: center !important;
    }


`

const StepNumber = styled.h3<{ position: 'left' | 'right' }>`
    font-size: 730px;
    position: absolute;
    color: rgba(255, 255, 255, 0.2);

    ${({ position }) => position === 'right' ? css`
        right: 0;
    `: css`
        left: 0;
    `}
`

const ImageCenter = styled.img<{ position: 'left' | 'right' }>`
    width: 240px;
    height: 240px;
    position: absolute;
    visibility: hidden;

    ${({ position }) => position === 'right' ? css`
        right: 250px;
    `: css`
        left: 250px;
    `}

    @media ${device.sm} {
        visibility: hidden;
        right: 0;
        left: 0;
        margin: auto;
    }
`

const TextContainer = styled.div<{ position: 'left' | 'right' }>`
    z-index: 2;
    margin: 50px 0;

    h3 {
        color: rgba(255,255,255,0.5);
        display: block;
        font-size: 24px;
        font-weight: bold;
        padding-left: 20px;
        padding-right: 20px;

        @media ${device.sm} {
            font-size: 20px;
        }
    }

    h2 {
        color: white;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.5px;
        margin: 35px 0;
        padding-left: 20px;
        padding-right: 20px;

        @media ${device.sm} {
            font-size: 21px;
        }
    }

    p {
        color: white;
        font-size: 21px;
        line-height: 1.45;
        margin-bottom: 20px;
        max-width: 850px;
        padding-left: 20px;
        padding-right: 20px;

        @media ${device.sm} {
            font-size: 16px;
        }
    }

    @media ${device.sm} {
        text-align: center;
    }

    ${({ position }) => position === 'right' ? css`
        margin-right: 10%;
    `: css`
        margin-left: 10%;
    `}

    @media ${device.sm} {
        margin-right: 0;
        margin-left: 0;
    }
`

const LetsTalk = styled.div`
    width: 100%;
    background: #965F8A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    button {
        width: fit-content;
        background: #4ac6b7;
        border-radius: 2px;
        border: 0 none;
        color: white;
        cursor: pointer;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.5px;
        padding: 20px 50px;
        position: relative;
        overflow: hidden;
        margin: 100px 0;
        transition: all 0.2s ease-in-out;

        animation: levitate 3s ease-in-out infinite;

        &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(255, 255, 255, 0.1);
    }

        &:hover {
            transform: scale(1.05);
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

            &::before {
            animation: fillButton 0.5s ease-in-out forwards;
            }
        }

        @media ${device.sm} {
            font-size: 24px;
        }
    }
`

export const WebSteps = () => {

    const history = useHistory();

    const { handleLogEvent } = useLogEvent();

    const {
        handleClickGoHome,
    } = useAnimations()

    const [languajeSelected, setLanguajeSelected] = useState<'es' | 'en'>('es');

    return (

        <Container id="home">

            <ContentContainer position='left' className='utils__fade-in-fast'>

                <StepNumber position='right'>1</StepNumber>

                <ImageCenter position='right' src={`${process.env.REACT_APP_CDN}/icon-web.png`} />

                <TextContainer position='left'>

                    <h3>1.</h3>

                    <h2>Nos contás tu idea...</h2>

                    <p>El proceso comienza con una charla sobre el estilo único y original de tu pagina.</p>

                </TextContainer>

            </ContentContainer>

            <ContentContainer position='right' style={{ backgroundColor: '#965F8A' }} className='utils__fade-in-fast'>

                <StepNumber position='left'>2</StepNumber>

                <ImageCenter position='left' src={`${process.env.REACT_APP_CDN}/icon-web.png`} />

                <TextContainer position='right'>

                    <h3>2.</h3>

                    <h2>Empezamos!</h2>

                    <p>Comienza la creactividad de nuestros diseñadores. Apuntamos a las estrellas.</p>

                </TextContainer>

            </ContentContainer>

            <ContentContainer position='left' style={{ backgroundColor: '#ff6f70' }} className='utils__fade-in-fast'>

                <StepNumber position='right'>3</StepNumber>

                <ImageCenter position='right' src={`${process.env.REACT_APP_CDN}/icon-web.png`} />

                <TextContainer position='left'>

                    <h3>3.</h3>

                    <h2>Presentamos bocetos...</h2>

                    <p>A veces hay más de un camino posible.</p>

                    <p>Una primera presentación con variedad de opciones sirve para despejar dudas acerca del rumbo a tomar.</p>


                </TextContainer>

            </ContentContainer>

            <ContentContainer position='right' style={{ backgroundColor: '#ffc26e' }} className='utils__fade-in-fast'>

                <StepNumber position='left'>4</StepNumber>

                <ImageCenter position='left' src={`${process.env.REACT_APP_CDN}/icon-web.png`} />

                <TextContainer position='right'>

                    <h3>4.</h3>

                    <h2>Hacemos tu página realidad!</h2>

                    <p>Nuestros programadores se ponen manos a la obra para construir tu sitio web único.</p>

                </TextContainer>

            </ContentContainer>

            <ContentContainer position='left' style={{ backgroundColor: '#92ddd3' }} className='utils__fade-in-fast'>

                <StepNumber position='right'>5</StepNumber>

                <ImageCenter position='right' src={`${process.env.REACT_APP_CDN}/icon-web.png`} />

                <TextContainer position='left'>

                    <h3>5.</h3>

                    <h2>Terminamos!</h2>

                    <p>¿Hay mayor placer que la puesta online de tu sitio web?</p>

                    <p>Vamos a averiguarlo.</p>

                </TextContainer>

            </ContentContainer>

            <LetsTalk>

                <button
                    onClick={async () => {

                        await history.push(`/${languajeSelected}/contact`);

                        await handleClickGoHome()

                        handleLogEvent('workTogetherWeb')
                    }}
                >
                    Contactanos!
                </button>

            </LetsTalk>

        </Container>

    )
}
