import { Header } from "../components/Header"
import { useEffect } from "react"
import { scrollFadeIn, scrollFadeInFast } from "../effects/Observers"
import { WebIntro } from "../components/WebIntro";
import { WebSteps } from "../components/WebSteps";

export const WebScreen = () => {

    useEffect(() => {

        scrollFadeIn()
        scrollFadeInFast()
    }, [])

    return (

        <div>

            <Header headerStyles={{ backgroundColor: `rgba(46, 161, 88, 0.9)`, borderColor: `rgb(46, 161, 88)` }} />

            <WebIntro />

            <WebSteps />

        </div>


    )
}
