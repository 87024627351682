import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';
import { WebScreen } from "../screens/WebScreen";
import { ContactScreen } from "../screens/ContactScreen";

export const AppRouter = () => {

    return (

        <Router>

            <div className='router-content'>

                <Switch>

                    <Route
                        exact
                        path='/:languaje(es)'
                        component={HomeScreen}
                    />

                    <Route
                        exact
                        path='/:languaje(es)/web'
                        component={WebScreen}
                    />

                    <Route
                        exact
                        path='/:languaje(es)/contact'
                        component={ContactScreen}
                    />

                    <Redirect to={`/${'es'}`} />
                    {/* <Redirect to={`/${localStorage.getItem('languaje') ?? 'es'}`} /> */}

                </Switch>

            </div>

        </Router>

    )
}
