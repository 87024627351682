import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useHistory } from "react-router-dom";
import { useAnimations } from "../effects/Effects";

const LogoContainer = styled.button<{ show: string, showmenu: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    left: 0px;
    bottom: -70px;
    top: 0;
    right: 30px;
    margin: auto;
    overflow: visible;
    width: fit-content;
    cursor: pointer;

    & > img {
        width: 100px;
        height: auto;
        transform: scale(1.2);

        @media ${device.sm} {
            width: 60px;
        }
    }

    ${({ show }) => show === 'true' ? css`

        animation: notOnTop 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) both;

        & > img {
            animation: lessSizeImg 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) both;
        }
    `: css`
        /* bottom: -70px;
        animation: onTop 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
        transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;


        & > img {
            transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            animation: moreSizeImg 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            transform: scale(1.2);
        } */
    `}  

    ${({ showmenu }) => showmenu === 'true' && css`
        animation: levitate 4s infinite;
    `}



`



interface Props {
    show: boolean;
    showMenu: boolean;
}

export const AppLogoComponent = ({ show, showMenu }: Props) => {

    const history = useHistory();

    const {
        handleClickGoHome,
    } = useAnimations()

    return (

        <LogoContainer
            show={show.toString()}
            showmenu={showMenu.toString()}
            onClick={async () => {
                await history.push('/');

                await handleClickGoHome()
            }}
        >

            <img
                src={`${process.env.REACT_APP_CDN}/logo.png`}
                alt='logo'
            />

        </LogoContainer>

    )
}
