import { useHistory, useLocation } from "react-router-dom";
import { useAnimations } from "../effects/Effects";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { useEffect, useState } from "react";
import { useLogEvent } from "../hooks/useLogEvent";

const NavContainer = styled.div`
    width: 500px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-between;

    @media ${device.lg} {
         width: 50%;
        max-width: 500px;

    }

    @media ${device.md} {
        visibility: hidden;
    }

`

const NavButton = styled.div`
    color: white;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 1.4px;
    padding: 10px;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    width: 150px;
    text-align: center;
    
    &:hover {
        border-color: white;
    }

    @media ${device.lg} {
        font-size: 14px;
        width: 130px;
    }

    @media ${device.md} {
        visibility: hidden;
    }

`

export const NavButtons = () => {

    const { handleLogEvent } = useLogEvent();

    const {
        handleClickGoPortfolio,
        handleClickGoWeb
    } = useAnimations()

    const history = useHistory();

    const [languajeSelected, setLanguajeSelected] = useState<'es' | 'en'>('es');

    return (

        <NavContainer>

            <NavButton onClick={async () => { await history.push(`/${languajeSelected}`); await handleClickGoPortfolio(); handleLogEvent('goPortfolioMenu'); }}>Portfolio</NavButton>

            <NavButton onClick={async () => { await history.push(`/${languajeSelected}/web`); await handleClickGoWeb(); handleLogEvent('goWebMenu'); }}>Desarrollo Web</NavButton>

        </NavContainer>

    )
}
