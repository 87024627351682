import styled, { css } from "styled-components"
import { useAnimations } from "../effects/Effects"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(255,195,111, 0.5);
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;


`

const BatataLogoImg = styled.div<{ img: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    left: 0px;
    top: 70px;
    right: 0px;
    margin: auto;
    overflow: visible;
    width: 150px;
    height: 150px;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    `}

    & > h1 {
        width: fit-content;    
        position: absolute;
        bottom: 30px;
        margin: auto;
        white-space: nowrap;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 18px;
        color: #333;
        text-align: center;

        &:first-of-type {
            color: rgba(255,195,111, 1);
            bottom: 70px;
            font-size: 0px;
        }

        &:last-of-type {
            color: rgba(255,195,111, 1);
            bottom: 0px;
            font-size: 0px;
        }

        @media ${device.sm} {
        font-size: 8px;
        text-transform: uppercase;
        letter-spacing: 14px;
    }
        
    }
`

const Clouds = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 10px;
    margin: auto;
    overflow: visible;
    width: 100%;
    animation: fadeIn 1s ease-in-out both 0.5s; 
`

const TableLeft = styled.div<{ img: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
    bottom: 80px;
    left: 51%;
    margin: auto;
    overflow: visible;
    width: 400px;
    height: 400px;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    `}

    @keyframes pulseServicesLeft {
        0%, 100% {
            transform: rotate(-25deg) scale(1);
        }
        50% {
            transform: rotate(-30deg) scale(1.05);
        }
    }

    @keyframes pulseServicesRight {
        0%, 100% {
            transform: rotate(15deg) scale(1);
        }
        50% {
            transform: rotate(10deg) scale(1.05);
        }
    }

    & > h2 {
        font-size: 70px;
        font-family: 'Caveat', 'cursive';
        color: rgba(131, 83, 121, 0.7);
        font-weight: 400;
        position: absolute;
        top: -30px;
        right: -10px;
        transform: rotate(15deg);
        animation: pulseServicesRight 3s ease-in-out infinite;

        @media ${device.sm} {
            font-size: 40px;
        }

        @media ${device.xs} {
            font-size: 30px;
        }
    }

    @media ${device.lg} {
        width: 400px;
        height: 400px;
    }

    @media ${device.md} {
        width: 300px;
        height: 300px;
        bottom: 100px;
        left: 55%
    }

    @media ${device.sm} {
        width: 200px;
        height: 200px;
        bottom: 50px;
        left: 40%
    }

    @media ${device.xs} {
        width: 150px;
        height: 150px;
        bottom: 50px;
        left: 45%;
        margin: auto;
    }
`

const TableRight = styled.div<{ img: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1.1;
    position: absolute;
    bottom: 20px;
    right: 46%;
    margin: auto;
    overflow: visible;
    width: 500px;
    height: 500px;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    `}

    & > h2 {
        font-size: 70px;
        font-family: 'Caveat', 'cursive';
        color: rgba(131, 83, 121, 0.7);
        font-weight: 400;
        position: absolute;
        top: -10px;
        left: 20px;
        transform: rotate(-25deg);
        animation: pulseServicesLeft 3s ease-in-out infinite;

        @media ${device.sm} {
            font-size: 40px;
        }

        @media ${device.xs} {
            font-size: 30px;
        }

    }

    @media ${device.lg} {
        width: 500px;
        height: 500px;
    }

    @media ${device.md} {
        width: 400px;
        height: 400px;
        bottom: 50px;
        right: 40%;
    }

    @media ${device.sm} {
        width: 250px;
        height: 250px;
        bottom: unset;
        top: 240px;
        right: 30%;
    }

    @media ${device.xs} {
        width: 200px;
        height: 200px;
        bottom: unset;
        top: 240px;
        right: 40%;
        margin: auto;
    }
`

export const Introduction = () => {

    const {
        handleClickGoPortfolio
    } = useAnimations()

    return (

        <Container id="home">

            <BatataLogoImg img={`${process.env.REACT_APP_CDN}/batata-logo-black.png`} className="utils__fade-in-fast">

                <h1>Batata</h1>

                <h1>Agencia Digital</h1>

                <h1>Servicio de paginas web</h1>

            </BatataLogoImg>

            <Clouds
                src={`${process.env.REACT_APP_CDN}/clouds.png`}
                alt='clouds'
            />

            <TableLeft img={`${process.env.REACT_APP_CDN}/table-left.png`} className="utils__fade-in">

                <h2>Desarrollo</h2>

            </TableLeft>

            <TableRight img={`${process.env.REACT_APP_CDN}/table-right.png`} className="utils__fade-in">

                <h2>Diseño</h2>

            </TableRight>

            <button
                className='introduction__scroll-container utils__fade-in'
                onClick={handleClickGoPortfolio}
            >

                <h2>
                    <span>D</span>
                    <span>E</span>
                    <span>S</span>
                    <span>L</span>
                    <span>I</span>
                    <span>Z</span>
                    <span>Á</span>
                </h2>

                <div className="introduction__scroll-arrow">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

            </button>

        </Container>

    )
}
