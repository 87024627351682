import { Portfolio } from "../components/Portfolio"
import { Header } from "../components/Header"
import { Introduction } from "../components/Introduction"
import { useEffect } from "react"
import { scrollFadeIn, scrollFadeInFast } from "../effects/Observers"
import { Footer } from "../components/Footer";
import { Meetings } from "../components/Meetings"

export const HomeScreen = () => {

    useEffect(() => {

        scrollFadeIn()
        scrollFadeInFast()
    }, [])

    return (

        <div className='home__main-content'>

            <Header />

            <Introduction />

            <Portfolio />

            <Meetings />

            <Footer />

        </div>


    )
}
