import { styled } from "styled-components";
import { CSSProperties, useEffect, useState } from "react";
import { AppLogoComponent } from "./AppLogoComponent"
import { NavButtons } from "./NavButtons";
import { device } from "../styles/stylesConfig";
import { MenuButton } from "./MenuButton";
import { Menu } from "./Menu";
import { useHistory, useLocation } from "react-router-dom";
import { useAnimations } from "../effects/Effects";
import { useLogEvent } from "../hooks/useLogEvent";

const HeaderContainer = styled.div`
    width: 100%;
    height: 54px;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 4;
    /* background-color: #3AB8A8E6;
    border-bottom: 1px solid rgb(74, 198, 183); */
    background-color: rgba(255,195,111, 1);
    border-bottom: 1px solid #ffc36f;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 7.3px 0px;
`

const ContactUsButton = styled.button`
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 200px;
    z-index: 3;
    padding: 5px 10px;
    border: 1px solid white;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: rgba(255,255,255, 0.2);
    }

    @media ${device.lg} {
        right: 50px;
        font-size: 14px;
    }

    @media ${device.md} {
        visibility: hidden;
    }
`

interface Props {
    headerStyles?: CSSProperties
}

export const Header = ({ headerStyles }: Props) => {

    const history = useHistory()

    const [show, setShow] = useState(true);

    const [showMenu, setShowMenu] = useState(false);

    const { handleLogEvent } = useLogEvent();

    const {
        handleClickGoHome,
    } = useAnimations()

    const [languajeSelected, setLanguajeSelected] = useState<'es' | 'en'>('es');

    const transitionNavBar = () => {

        if (window.scrollY > 50) {
            setShow(true);
        } else {
            setShow(false);
        }

    }

    useEffect(() => {

        transitionNavBar()

        window.addEventListener('scroll', transitionNavBar);

        return () => window.removeEventListener('scroll', transitionNavBar);

    }, []);

    return (

        <HeaderContainer style={{ ...headerStyles }}>

            <AppLogoComponent show={show} showMenu={showMenu} />

            <NavButtons />

            <ContactUsButton
                onClick={async () => {

                    await history.push(`/${languajeSelected}/contact`);

                    await handleClickGoHome()

                    handleLogEvent('goContactMenu');
                }}
            >
                Contacto
            </ContactUsButton>

            <MenuButton showMenu={showMenu} setShowMenu={setShowMenu} />

            <Menu showMenu={showMenu} setShowMenu={setShowMenu} />

        </HeaderContainer>

    )
}
