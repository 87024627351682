
export const useAnimations = () => {

    const handleClickGoHomeRefresh = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    }

    const handleClickGoHome = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoPortfolio = () => {
        const element = document.getElementById('portfolio');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoWeb = () => {
        const element = document.getElementById('web');
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    }

    const handleClickGoMeetings = () => {
        const element = document.getElementById('meetings');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return {
        handleClickGoHomeRefresh,
        handleClickGoHome,
        handleClickGoPortfolio,
        handleClickGoWeb,
        handleClickGoMeetings
    }

}